import React, { FC } from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import SvgImageIcon from 'common/SvgImageIcon';

import { BrandGoalsSectionProps } from './models';

import './BrandGoalsSection.scss';

const BrandGoalsSection: FC<BrandGoalsSectionProps> = ({
  brandGoalsSection: { image, title, description, quote, icon, disclaimer },
}) => {
  // added modifier to section
  const contentClasses = classNames([
    'brand-goals-section__our-combat-section',
    {
      'brand-goals-section__our-combat-section--disclaimer': Boolean(disclaimer),
    },
  ]);

  return (
    <div className="brand-goals-section">
      <div className="brand-goals-section__image">
        <Image imageData={image} alt={title} />
      </div>
      <div className="brand-goals-section__content">
        <div className={contentClasses}>
          <h2 className="brand-goals-section__title">{title}</h2>
          <DangerouslySetInnerHtml
            html={description}
            className="brand-goals-section__description"
          />
          {disclaimer ? (
            <DangerouslySetInnerHtml
              className="brand-goals-section__disclaimer"
              html={disclaimer}
            />
          ) : null}
        </div>
        <div className="brand-goals-section__our-mission-section">
          <SvgImageIcon icon={icon} iconAlt={quote} customClassName="brand-goals-section__icon" />
          <p className="brand-goals-section__quote">{quote}</p>
        </div>
      </div>
    </div>
  );
};

export default BrandGoalsSection;
