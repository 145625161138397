import React, { FC } from 'react';

import MarketRedirectProduct from 'components/MarketRedirectProduct';

import { MarketRedirectSectionProps } from './models';

import './MarketRedirectSection.scss';

const MarketRedirectSection: FC<MarketRedirectSectionProps> = ({ marketRedirectProducts }) => (
  <div className="market-redirect-section">
    {marketRedirectProducts.map((marketProduct) => (
      <MarketRedirectProduct marketRedirectProduct={marketProduct} key={marketProduct.title} />
    ))}
  </div>
);

export default MarketRedirectSection;
