import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import BrandGoalsSection from 'components/BrandGoalsSection';
import ImageSeparator from 'components/ImageSeparator';
import MarketRedirectSection from 'components/MarketRedirectSection';
import ProductUsageSection from 'components/ProductUsageSection';
import SingleLineBanner from 'components/SingleLineBanner';
import TopBanner from 'components/TopBanner';
import VideoSection from 'components/VideoSection';

const UniversePage: FC<UniversePageTypes.UniversePageProps> = ({
  data: {
    universePage: {
      urls,
      seo,
      topBanner,
      videoSection,
      singleLineBanner,
      brandGoalsSection,
      imageSeparator,
      productUsageSection,
      marketRedirectSection,
    },
  },
}) => (
  <Layout headerTransparent seo={seo} urls={urls}>
    <TopBanner topBanner={topBanner} />
    <VideoSection videoSection={videoSection} />
    <SingleLineBanner singleLineBanner={singleLineBanner} />
    <BrandGoalsSection brandGoalsSection={brandGoalsSection} />
    <ProductUsageSection productUsageSectionData={productUsageSection} />
    {imageSeparator ? <ImageSeparator imageSeparator={imageSeparator} /> : null}
    {marketRedirectSection ? (
      <MarketRedirectSection
        marketRedirectProducts={marketRedirectSection?.marketRedirectProducts}
      />
    ) : null}
  </Layout>
);

export const query = graphql`
  query ($lang: String) {
    universePage(lang: { eq: $lang }) {
      urls {
        ...UrlsFragment
      }
      seo {
        ...SEOStructureFragment
      }
      singleLineBanner {
        ...SingleLineBannerFragment
      }
      topBanner {
        ...TopBannerFragment
      }
      videoSection {
        ...VideoSectionFragment
      }
      imageSeparator {
        ...ImageWithAltTextFragment
      }
      brandGoalsSection {
        ...BrandGoalsSectionFragment
      }
      marketRedirectSection {
        ...MarketRedirectProductsFragment
      }
      productUsageSection {
        ...ProductUsageSectionFragment
      }
    }
  }
`;

export default UniversePage;
