import React, { FC } from 'react';

import Image from 'common/Image';

import { TopBannerProps } from './models';

import './TopBanner.scss';

const TopBanner: FC<TopBannerProps> = ({ topBanner: { title, backgroundImage } }) => (
  <div className="top-banner">
    <Image className="top-banner__background-image" imageData={backgroundImage} alt={title} />
    <h1 className="top-banner__title">{title}</h1>
  </div>
);

export default TopBanner;
