const getYouTubeVideoId = (url) => {
  const ytRegularExpression = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\\&v=)([^#\\&?]*).*/;

  const result = url.match(ytRegularExpression);

  if (result && result.length > 1) {
    return result[2];
  }

  return '';
};

export default getYouTubeVideoId;
