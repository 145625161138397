import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import VideoElement from 'components/VideoElement';

import { VideoSectionProps } from './models';

import './VideoSection.scss';

const VideoSection: FC<VideoSectionProps> = ({
  videoSection: { title, imageAltText, description, image, videoThumbnail, videoURL, videoAltText },
}) => (
  <div className="video-section">
    <div className="video-section__content">
      <h2 className="video-section__title">{title}</h2>
      <DangerouslySetInnerHtml html={description} className="video-section__description" />
      <Image imageData={image} alt={imageAltText || title} className="video-section__image" />
    </div>
    <VideoElement
      title={title}
      videoURL={videoURL}
      thumbnail={videoThumbnail}
      altText={videoAltText}
    />
  </div>
);

export default VideoSection;
