import React, { FC, useState } from 'react';
import YouTube, { Options } from 'react-youtube';

import Image from 'common/Image';
import getYouTubeVideoId from 'utils/getYouTubeVideoId';

import { VideoElementProps } from './models';

import './VideoElement.scss';

const VideoElement: FC<VideoElementProps> = ({ title, videoURL, thumbnail, altText }) => {
  const [playVideo, setPlayVideo] = useState(false);

  const ytVideoId = getYouTubeVideoId(videoURL);

  const playerOptions = {
    title,
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  } as Options;

  const handleThumbnailOnClick = () => {
    if (ytVideoId) {
      setPlayVideo(true);
    }
  };

  return (
    <div className="video-element">
      <div className="video-element__container">
        {playVideo ? (
          <YouTube videoId={ytVideoId} opts={playerOptions} />
        ) : (
          <div
            role="button"
            onClick={handleThumbnailOnClick}
            onKeyDown={handleThumbnailOnClick}
            className="video-element__thumbnail"
            tabIndex={0}
          >
            <Image imageData={thumbnail} alt={altText} />
            {ytVideoId && <i className="fas fa-play-circle" />}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoElement;
