export enum VisualType {
  Rounded = 'rounded',
  Squared = 'squared',
}

export enum VisualVariant {
  Blue = 'blue',
  Pink = 'pink',
  Transparent = 'transparent',
  ReversedPink = 'reversed-pink',
  Cashmere = 'cashmere',
  Rajah = 'rajah',
  Brown = 'brown',
  ReversedBlack = 'reversed-black',
}
