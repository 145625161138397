import React, { FC } from 'react';

import { VisualVariant } from 'common/Button/enums';
import ProductUsageSectionCard from 'components/ProductUsageSectionCard';

import { ProductUsageSectionProps } from './models';

import './ProductUsageSection.scss';

const ProductUsageSection: FC<ProductUsageSectionProps> = ({ productUsageSectionData }) => {
  if (!productUsageSectionData) return null;

  const { products } = productUsageSectionData;

  return (
    <div className="product-usage-section">
      {products?.map((product, index) => (
        <ProductUsageSectionCard
          productUsageSectionData={{
            ...product,
            cardOrder: index,
            visualVariant: index % 2 === 0 ? VisualVariant.Blue : VisualVariant.Pink,
          }}
          key={product.firstTitle}
        />
      ))}
    </div>
  );
};

export default ProductUsageSection;
