import React, { FC } from 'react';

import buttonClassesHelper from './buttonClassHelper';
import { ButtonProps } from './models';

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  className,
  visualType,
  visualVariant,
  ariaLabel,
}) => {
  const buttonClasses = buttonClassesHelper(className, visualType, visualVariant);

  return (
    <button type="button" onClick={onClick} aria-label={ariaLabel} className={buttonClasses}>
      {children}
    </button>
  );
};

export default Button;
