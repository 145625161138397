import React, { FC } from 'react';
import classNames from 'classnames';

import { SingleLineBannerProps } from './models';

import './SingleLineBanner.scss';

const SingleLineBanner: FC<SingleLineBannerProps> = ({ singleLineBanner: { text, color } }) => (
  <div
    className={classNames(
      'single-line-banner',
      `single-line-banner--${color?.label.toLowerCase()}`
    )}
  >
    <p className="single-line-banner__title">{text}</p>
  </div>
);

export default SingleLineBanner;
