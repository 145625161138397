import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import Button from 'common/Button';
import { VisualType, VisualVariant } from 'common/Button/enums';
import Image from 'common/Image';

import { MarketRedirectProductProps } from './models';

import './MarketRedirectProduct.scss';

const MarketRedirectProduct: FC<MarketRedirectProductProps> = ({
  marketRedirectProduct: {
    title,
    image,
    imageAltText,
    cardColor: { label: color },
    redirectButton: { url, name },
  },
}) => {
  const visualColor = VisualVariant[color] ?? VisualVariant.Pink;

  return (
    <div
      className={classNames(
        'market-redirect-product',
        `market-redirect-product--${color?.toLowerCase()}-theme`
      )}
    >
      <div className="market-redirect-product__image">
        <Image imageData={image} alt={imageAltText || title} />
      </div>
      <h2 className="market-redirect-product__title">{title}</h2>
      <div className="market-redirect-product__button">
        <Link to={url}>
          <Button visualType={VisualType.Squared} visualVariant={visualColor} ariaLabel={name}>
            {name}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default MarketRedirectProduct;
