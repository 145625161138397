import React, { FC } from 'react';

import Image from 'common/Image/Image';

import { ImageSeparatorProps } from './models';

import './ImageSeparator.scss';

const ImageSeparator: FC<ImageSeparatorProps> = ({ imageSeparator: { image, altText } }) => (
  <div className="image-separator">
    <Image imageData={image} alt={altText} />
  </div>
);
export default ImageSeparator;
