import React, { FC, useState } from 'react';

import { SvgImageIconProps } from './models';

const SvgImageIcon: FC<SvgImageIconProps> = ({
  icon: { url, fallbackUrl },
  iconAlt,
  customClassName,
}) => {
  const [imageUrl, setImageUrl] = useState(url);

  const handleImageError = () => {
    setImageUrl(fallbackUrl);
  };

  return (
    <img
      className={customClassName}
      src={imageUrl}
      alt={iconAlt}
      onError={handleImageError}
      data-testid="image"
    />
  );
};

export default SvgImageIcon;
