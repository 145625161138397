import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import { VisualType } from 'common/Button/enums';
import Image from 'common/Image';

import { ProductUsageSectionCardProps } from './models';

import './ProductUsageSectionCard.scss';

const ProductUsageSectionCard: FC<ProductUsageSectionCardProps> = ({
  productUsageSectionData: {
    image,
    altText,
    firstDescription,
    secondDescription,
    secondTitle,
    firstTitle,
    visualVariant,
    cardOrder,
    button: { name, url },
    ariaLabel,
  },
}) => {
  const orderVariant = cardOrder % 2 === 0 ? 'side-left' : 'side-right';

  return (
    <div
      key={firstTitle}
      className={classNames(
        `product-usage-section-card`,
        `product-usage-section-card--${visualVariant}`,
        `product-usage-section-card--${orderVariant}`
      )}
    >
      <div className="product-usage-section-card__image-section">
        <Image imageData={image} alt={altText} />
        <h3 className="product-usage-section-card__first-title">{firstTitle}</h3>
      </div>
      <div className="product-usage-section-card__description-section">
        <h3 className="product-usage-section-card__second-title">{secondTitle}</h3>
        <h3 className="product-usage-section-card__first-description">{firstDescription}</h3>
        <DangerouslySetInnerHtml
          className="product-usage-section-card__second-description"
          html={secondDescription}
        />
        <Link to={url}>
          <Button
            ariaLabel={ariaLabel}
            visualType={VisualType.Squared}
            visualVariant={visualVariant}
          >
            {name}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ProductUsageSectionCard;
